<template>
  <div>
    <el-form ref="form" :model="form" label-width="120px" style="display: flex;">
      <div class="el-lt" style="width:85%;">
        <el-row :span="24">
          <el-col :span="8">
            <el-form-item label="平台/站点" :class="$i18n.locale">
              <el-cascader
                v-model="form.platformSite"
                clearable
                filterable
                collapse-tags
                :options="alldatas"
                :props=" { value: 'dropDownId', label: 'dropDownName', children: 'dropDownList', multiple:true}"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="操作人" :class="$i18n.locale">
              <el-select v-model="form.operateId" clearable filterable style="width:100%;">
                <el-option v-for="item in useroptions" :key="item.id" :label="item.username" :value="item.id" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="操作时间" :class="$i18n.locale">
              <el-date-picker
                v-model="form.value1"
                value-format="yyyy-MM-dd"
                type="daterange"
                range-separator="~"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              />
            </el-form-item>
          </el-col>
        </el-row>

      </div>
      <el-row class="el-rt" style="width:25%;">
        <el-col class="row-center">
          <el-button type="primary" :loading="Loading" @click="queryClickSearch()">{{ $t('page.search') }}</el-button>
          <el-button @click="resetClick()">{{ $t('page.reset') }}</el-button>
        </el-col>
      </el-row>
    </el-form>

    <el-row :span="24" class="mb-3">
      <el-col :span="12">
        <el-button v-permission="'add'" type="primary" @click="handleAdd()">新增</el-button>
        <el-button v-permission="'batchImport'" class="mr-3" type="primary" @click="importDialogVisible=true">批量导入</el-button>
        <el-button v-permission="'deleteA'" class="mr-3" type="danger" @click="handleDelete()">删除</el-button>
      </el-col>
      <el-col :span="12" style="text-align: right;">
        <el-button v-permission="'exportA'" class="mr-3" type="primary" @click="exportDetail">导出</el-button>
      </el-col>
    </el-row>

    <vxe-table
      ref="xTable"
      v-loading="Loading"
      max-height="500px"
      border
      :data="replenishTableData"
      align="center"
      keep-source
      :edit-rules="validRules"
      :edit-config="{trigger: 'manual', mode: 'row', autoClear: false}"
      @checkbox-all="({records})=>{selectlist=records}"
      @checkbox-change="({records})=>{selectlist=records}"
    >
      <vxe-table-column type="checkbox" width="60" />
      <vxe-table-column type="index" width="60" title="序号" />
      <vxe-table-column field="platformName" title="平台" width="80" />
      <vxe-table-column field="siteName" title="站点" width="80" />

      <vxe-table-column field="orderDays" title="下单所需时间（天）" :edit-render="{}" min-width="100">
        <template #edit="{ row }">
          <vxe-input v-model="row.orderDays" type="integer" min="0" max="999999" placeholder="请输入自然数" />
        </template>
      </vxe-table-column>
      <vxe-table-column field="cnWarehouseDays" title="国内仓处理时间（天）" :edit-render="{}" min-width="100">
        <template #edit="{ row }">
          <vxe-input v-model="row.cnWarehouseDays" type="integer" min="0" max="999999" placeholder="请输入自然数" />
        </template>
      </vxe-table-column>
      <vxe-table-column field="platformShelfDays" title="平台上架所需时间（天）" :edit-render="{}" min-width="100">
        <template #edit="{ row }">
          <vxe-input v-model="row.platformShelfDays" type="integer" min="0" max="999999" placeholder="请输入自然数" />
        </template>
      </vxe-table-column>
      <vxe-table-column field="replenishFrequency" title="补货频率（天/次）" :edit-render="{}" min-width="80">
        <template #edit="{ row }">
          <vxe-input v-model="row.replenishFrequency" type="integer" min="0" max="999999" placeholder="请输入自然数" />
        </template>
      </vxe-table-column>
      <vxe-table-column field="remark" title="备注" :edit-render="{}" width="80">
        <template #edit="{ row }">
          <vxe-input v-model="row.remark" type="textarea" maxlength="100" placeholder="请输入备注" />
        </template>
      </vxe-table-column>

      <vxe-table-column field="modifyByName" title="操作人" width="80" />
      <vxe-table-column field="modifyTime" title="操作时间" width="100" />

      <vxe-table-column title="操作" width="160">
        <template #default="{ row }">
          <template v-if="$refs.xTable.isActiveByRow(row)">
            <vxe-button @click="saveRowEvent(row)">保存</vxe-button>
            <vxe-button @click="cancelRowEvent(row)">取消</vxe-button>
          </template>
          <template v-else>
            <vxe-button @click="editRowEvent(row)">编辑</vxe-button>
          </template>
        </template>
      </vxe-table-column>
    </vxe-table>

    <Paging :pager="pager" end @pagination="pagerUpdate" />

    <!-- 新增修改弹窗 -->
    <el-dialog title="新增补货时效" :visible.sync="dialogVisible" width="40%" :close-on-click-modal="false" center>
      <el-form ref="addForm" :model="addForm" label-width="140px" :rules="rules">
        <el-form-item label="平台/站点" prop="platformSite">
          <el-cascader
            v-model="addForm.platformSite"
            clearable
            filterable
            collapse-tags
            :options="limitedAlldatas"
            style="width:100%;"
            :props=" { value: 'value', label: 'platformName', children: 'siteList'}"
          />
        </el-form-item>
        <el-form-item label="下单所需时间" prop="orderDays">
          <div>
            <el-input-number v-model="addForm.orderDays" controls-position="right" placeholder="请输入自然数" :precision="0" :min="0" :max="999999" style="width:80%;" />天
          </div>
        </el-form-item>
        <el-form-item label="国内仓处理时间" prop="cnWarehouseDays">
          <div>
            <el-input-number v-model="addForm.cnWarehouseDays" controls-position="right" placeholder="请输入自然数" :precision="0" :min="0" :max="999999" style="width:80%;" />天
          </div>
        </el-form-item>
        <el-form-item label="平台上架所需时间" prop="platformShelfDays">
          <div>
            <el-input-number v-model="addForm.platformShelfDays" controls-position="right" placeholder="请输入自然数" :precision="0" :min="0" :max="999999" style="width:80%;" />天
          </div>
        </el-form-item>
        <el-form-item label="补货频率" prop="replenishFrequency">
          <div>
            <el-input-number v-model="addForm.replenishFrequency" controls-position="right" placeholder="请输入自然数" :precision="0" :min="0" :max="999999" style="width:80%;" />天
          </div>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input v-model="addForm.remark" type="textarea" maxlength="100" show-word-limit placeholder="请输入内容" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible=false">取 消</el-button>
        <el-button type="primary" :loading="submitLoading" @click="dialogVisibleSure">提 交</el-button>
      </span>
    </el-dialog>

    <!-- 批量导入 弹窗 -->
    <BatchImport v-model="importDialogVisible" @refresh="queryClick" />
  </div>
</template>

<script>
import Paging from '@/components/Pagination'
import { handleDownload, handleMonitorParams } from '@/utils'
import { usersList, getplatformSite, getlimitedplatformSite, getReplenishApi, replenishExport, InsertReplenish, ModifyReplenish, DeleteReplenish } from '@/api/scm-api'
import BatchImport from './components/BatchImport'
import { trackSiteSearch, handleBreadTitle, trackExportEvent, trackDialogEvent } from '@/utils/monitor'
import { merge } from 'lodash'
export default {
  components: { Paging, BatchImport },

  data() {
    return {
      form: {
        value1: [],
        style: '',
        vendorId: '',
        platformSite: ''
      },
      pager: {
        current: 1,
        size: 10,
        pages: 1
      },
      useroptions: [],
      alldatas: [],
      limitedAlldatas: [],
      Loading: false,
      dialogVisible: false, // 新增修改弹窗
      importDialogVisible: false,
      selectlist: [],
      addForm: {
        platformSite: '',
        orderDays: '',
        platformShelfDays: '',
        replenishFrequency: '',
        cnWarehouseDays: '',
        remark: ''
      }, // 新增
      rules: {
        platformSite: { required: true, message: this.$t('page.selectPlaceholder'), trigger: 'blur' },
        orderDays: { required: true, message: this.$t('page.inputPlaceholder'), trigger: 'blur' },
        platformShelfDays: { required: true, message: this.$t('page.inputPlaceholder'), trigger: 'blur' },
        replenishFrequency: { required: true, message: this.$t('page.inputPlaceholder'), trigger: 'blur' },
        cnWarehouseDays: { required: true, message: this.$t('page.inputPlaceholder'), trigger: 'blur' }
      },
      submitLoading: false,
      replenishTableData: [], // 修改
      validRules: {
        orderDays: [{ required: true, message: this.$t('page.inputPlaceholder') }],
        platformShelfDays: [{ required: true, message: this.$t('page.inputPlaceholder') }],
        replenishFrequency: [{ required: true, message: this.$t('page.inputPlaceholder') }],
        cnWarehouseDays: [{ required: true, message: this.$t('page.inputPlaceholder') }]
      }
    }
  },
  computed: {
    replenishParams() {
      const [operateTimeStart, operateTimeEnd] = this.form.value1 || []
      const { operateId, platformSite } = this.form
      const operateIdList = operateId ? [operateId] : []
      const arr = []
      if (Array.isArray(platformSite)) {
        for (let i = 0; i < platformSite.length; i++) {
          const platformId = platformSite[i][0]
          const siteId = platformSite[i][1]
          arr.push({ platformId: platformId, siteId: siteId })
        }
      }
      return Object.assign({}, this.pager, { operateIdList, operateTimeStart, operateTimeEnd }, { platformSiteList: arr })
    },
    addReplenishParams() {
      const { platformSite } = this.addForm
      const temp1 = platformSite[0].split('&&')
      const platformId = parseInt(temp1[0])
      const platformName = temp1[1]
      const temp2 = platformSite[1].split('&&')
      const siteId = parseInt(temp2[0])
      const siteName = temp2[1]
      return Object.assign({}, this.pager, { platformId: platformId, platformName: platformName, siteId: siteId, siteName: siteName })
    }
  },
  watch: {
    dialogVisible(val) {
      if (val) {
         this.$refs.addForm?.clearValidate()
        this.$refs.addForm?.resetFields()
      }
    }
  },
  created() {

  },
  mounted() {
    this._usersList()
    this._listPlatformAndSite()
    this._limitedlistPlatformAndSite()
    this.queryClick(1)
  },
  methods: {
    queryClickSearch() {
      this.queryClick(1)
      trackSiteSearch(
        JSON.stringify(handleMonitorParams(merge(this.replenishParams, this.pager))),
        handleBreadTitle(this.$route),
        this.pager.size
      )
    },
    pagerUpdate(val) {
      this.pager = val
      this.queryClick()
    },
    resetClick() {
      this.form = this.$options.data.call(this).form
      this.selectlist = []
      this.queryClick(1)
    },
    async queryClick(flag) {
      try {
        this.Loading = true
        flag && flag === 1 ? this.pager.current = 1 : ''
        const { code, datas: { records, pager }} = await getReplenishApi(this.replenishParams, this.pager)
        if (code === 0) {
          this.pager = pager
          this.replenishTableData = records
        }
        this.selectlist = []
      } catch (err) {
        console.log(err)
      } finally {
        this.Loading = false
      }
    },

    // 导出
    async exportDetail() {
      try {
        this.exportLoading = true
        const params = {}
        const idList = []
        if (Array.isArray(this.selectlist) && this.selectlist.length > 0) {
          this.selectlist.map(item => idList.push(item.id))
          Object.assign(params, { idList: idList })
        } else {
          Object.assign(params, this.replenishParams)
        }
        const data = await replenishExport(params)
        handleDownload(data, '补货时效配置文件.xlsx')
        this.exportLoading = false
        trackExportEvent(handleBreadTitle(this.$route))
      } finally {
        this.exportLoading = false
      }
    },

    // 新增
    handleAdd() {
      this.addForm = {}
      this.dialogVisible = true
      trackDialogEvent(handleBreadTitle(this.$route) + '新增')
    },
    async dialogVisibleSure() {
      this.$refs['addForm'].validate(async valid => {
        if (valid) {
          try {
            this.submitLoading = true
            const obj = Object.assign({}, this.addForm, this.addReplenishParams)
            await InsertReplenish(obj)
            this.submitLoading = false
            this.dialogVisible = false
            // this.edit = false
            this.queryClick(0)
          } catch (err) { console.log(err) } finally {
            this.submitLoading = false
          }
        }
      }
      )
    },

    // 删除
    handleDelete() {
      if (Array.isArray(this.selectlist) && this.selectlist.length) {
        this.$confirm(`你选中了${this.selectlist.length}条数据进行删除，该操作无法撤销，请确认！`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async() => {
          const idList = this.selectlist.map(item => item.id)
          await DeleteReplenish(idList)
          this.queryClick(0)
          this.selectlist = []
          trackDialogEvent(handleBreadTitle(this.$route) + '删除')
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
      } else {
        this.$message({
          message: '请至少选择一条数据删除',
          type: 'warning'
        })
        return false
      }
    },
    // 下拉信息获取
    async _usersList() {
      const { datas } = await usersList()
      this.useroptions = datas
    },
    async _listPlatformAndSite() {
      const { datas } = await getplatformSite()
      this.alldatas = datas
    },
    // 带权限控制的平台站点下拉
    async _limitedlistPlatformAndSite() {
      const { datas } = await getlimitedplatformSite()
      this.limitedAlldatas = datas.map(data => ({
        ...data,
        siteList: data.siteList.map(item => ({
          platformId: item.siteId,
          platformName: item.siteName
        }))
      }))
      this.getDataTree(this.limitedAlldatas)
    },
    getDataTree(data) {
      if (!Array.isArray(data)) return
      for (let i = 0; i < data.length; i++) {
        data[i].value = `${data[i].platformId}&&${data[i].platformName}`
        this.getDataTree(data[i].siteList)
      }
    },

    // 操作的修改、保存与取消
    editRowEvent(row) {
      const $table = this.$refs.xTable
      $table.setActiveRow(row)
      trackDialogEvent(handleBreadTitle(this.$route) + '修改')
    },
    async saveRowEvent(row) {
      const $table = this.$refs.xTable
      const errMap = await $table.validate(row)
      if (!errMap) {
        $table.clearActived().then(async() => {
          this.loading = true
          const obj = Object.assign({}, row, { id: row.id })
          await ModifyReplenish(obj)
          this.queryClick(0)
          this.loading = false
          this.$notify({ message: '保存成功！', type: 'success' })
        })
      }
    },
    cancelRowEvent(row) {
      const $table = this.$refs.xTable
      $table.clearActived().then(() => {
        // 还原行数据
        $table.revertData(row)
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
